import apiClient from ".";

export const getFormTemplate = async (FormTemplateId, token) => {
  try {
    const response = await apiClient.get(`/form-templates/${FormTemplateId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const listFormTemplatesByActivity = async (activityId, token) => {
  try {
    const response = await apiClient.get(`/form-templates/list/${activityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}